.pane-entity-view.pane-node {
  @include set-container-width($content-width);

  .field-name-field-page-gallery-images {
    margin-top: 60px;
    .field-items {
      display: flex;
      flex-direction: column;

      @include breakpoint(600px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .field-item {
        width: 100%;
        margin-bottom: 20px;

        @include breakpoint(600px) {
          width: 48%;
          margin-bottom: 4%;
        }
        @include breakpoint($bp-tablet) {
          width: 47%;
          margin-bottom: 6%;
        }

        img {
          display: block;
          width: 100%;
          max-width: 360px;
          margin: 0 auto;

          @include breakpoint(600px) {
            max-width: none;
            margin: 0;
          }
        }
      }
    }
  }

}