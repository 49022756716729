.entity-bean.bean-page-title-with-infotext {
  @include set-container-width($content-width);

  .page-title-wrapper {
    display: flex;
    flex-direction: column;

    @include breakpoint(550px) {
      flex-direction: row;
      justify-content: space-between;
    }

    .page-title {
      width: 100%;
      text-align: center;

      h2 {
        margin: 0;
        white-space: nowrap;
      }

      @include breakpoint(550px) {
        width: 33.33%;
        text-align: left;
      }
    }
    .contact-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      @include breakpoint(550px) {
        width: 66.66%;
      }

      @include breakpoint($bp-tablet) {
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
      }

      .contact-phone {
        white-space: nowrap;
        text-align: center;
        width: 240px;
        margin: 13px auto 10px;
        @include breakpoint(550px) {
          width: 240px;
          margin-right: 0;
        }
        @include breakpoint($bp-tablet) {
          padding-top: 13px;
          margin-left: 0;
          margin-top: 0;
          margin-bottom: 0;
        }
        a {
          font-family: $robotoBold;
          font-size: 17px;
        }
      }
      .contact-us {
        align-self: normal;
        white-space: nowrap;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(550px) {
          width: 240px;
          margin-right: 0;
        }
        @include breakpoint($bp-tablet) {
          margin-left: 20px;
        }

        a {
          display: block;
          font-family: $robotoBold;
          font-size: 17px;
          text-transform: uppercase;
          color: $white;
          background-color: $brown;
          text-align: center;
          width: 240px;
          padding: 5px 15px;
          @include set-transition();
          &:hover {
            background-color: $brown-dark;
          }
        }
      }
    }
  }



}