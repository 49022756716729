.pane-bean-reviews {
  .pane-content {
    .bean-reviews {

      .block-title {
        @include set-container-width($container-width, 0);
      }

      .slider-wrapper {
        background-color: $grey-light;
        .slider-inner {
          position: relative;
          @include set-container-width(900px, 0);
          padding: 35px 100px;
          .slides {
            position: relative;

            .slide {
              position: absolute;
              top: 50%;
              left: 0;
              width: 100%;
              transform: translateY(-50%);
              @include clearfix();

              .image {
                text-align: center;
                margin-bottom: 20px;
                max-width: 85px;
                img {
                  border-radius: 100%;
                }
                @include breakpoint($bp-tablet) {
                  float: left;
                  text-align: left;
                  margin-bottom: 0;
                }
              }

              .info {
                font-size: 14px;
                .name {
                  font-family: $robotoRegular;
                  margin-bottom: 10px;
                }
              }

              @include breakpoint($bp-tablet) {
                .image + .info {
                  margin-left: 115px;
                }
              }

              &:not(:first-of-type) {
                display: none;
              }
            }
          }

          .prev,
          .next {
            position: absolute;
            top: 0;
            height: 100%;
            width: 50px;
            background: url(../images/slider_arrow.png) center center no-repeat;
            cursor: pointer;
          }
          .prev {
            left: 0;
          }
          .next {
            right: 0;
            transform: rotate(180deg);
          }

        }

      }

    }
  }
}
