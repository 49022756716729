.entity-bean.bean-wines-catalog {
  @include set-container-width($content-width);

  .field-name-field-products-infotext {
    margin-bottom: 35px;
  }

  .wines-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -35px;
    .wine-wrapper {
      padding: 0 35px;
      margin-bottom: 45px;

      .wine {
        margin: 0 auto;
        width: 220px;

        .image {
          max-width: 220px;
          margin-bottom: 5px;
        }
        .name {
          font-size: 18px;
          margin-bottom: 5px;
          span {
            font-family: $robotoRegular;
          }
        }
        .info {
          font-size: 18px;
          .price {
            font-family: $robotoRegular;
            color: $brown;
          }
          .alcvol,
          .vol {
            font-size: 14px;
            white-space: nowrap;
          }
        }
      }
    }
  }

}