.room-search-wrapper {
  display: none;
  position: absolute;
  top: 60px;
  right: 20px;
  max-width: 300px;

  @include breakpoint($bp-tablet) {
    display: block;
  }
}

.room-search-form-block {
  padding: 0 20px 20px;
  min-height: 210px;

  form.room-search-form {
    .form-item {
      width: 50%;
      margin: 0;

      input {
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $grey_dark;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $grey_dark;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $grey_dark;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $grey_dark;
        }
      }

      input, select {
        margin: 0;
        width: 100%;
        color: $grey_dark;
        border: none;
        outline: none;
      }

      label {
        color: $white;
      }
      .description {
        display: none;
      }
    }
    .container-inline-date {
      width: 50%;
      .form-item {
        width: 100%;
      }
    }
    .form-type-select{
      label {
        margin-bottom: 5px;
      }
    }

    .form-row {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-bottom: 20px;

      > div.container-inline-date,
      > div.form-type-select {
        &:first-of-type {
          padding-right: 10px;
        }
        &:last-of-type {
          padding-left: 10px;
        }
      }

    }

    input.form-submit {
      margin-top: 15px;
      position: relative;
      display: inline-block;
      font-size: 17px;
      line-height: 21px;
      text-align: center;
      text-transform: uppercase;
      min-width: 240px;
      padding: 10px 20px;
      background-color: rgba($white, .8);
      color: $brown-dark;
      &:hover {
        color: $brown;
        opacity: .8;
      }
    }

  }
}

.ui-datepicker {
  z-index: 15 !important;
}