@font-face {
  font-family: 'RobotoRegular';
  src: url('../fonts/roboto_regular/Roboto-Regular-webfont.eot');
  src: url('../fonts/roboto_regular/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto_regular/Roboto-Regular-webfont.woff') format('woff'),
  url('../fonts/roboto_regular/Roboto-Regular-webfont.ttf') format('truetype'),
  url('../fonts/roboto_regular/Roboto-Regular-webfont#RobotoRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoBold';
  src: url('../fonts/roboto_bold/Roboto-Bold-webfont.eot');
  src: url('../fonts/roboto_bold/Roboto-Bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto_bold/Roboto-Bold-webfont.woff') format('woff'),
  url('../fonts/roboto_bold/Roboto-Bold-webfont.ttf') format('truetype'),
  url('../fonts/roboto_bold/Roboto-Bold-webfont.svg#RobotoBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoLight';
  src: url('../fonts/roboto_light/Roboto-Light-webfont.eot');
  src: url('../fonts/roboto_light/Roboto-Light-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto_light/Roboto-Light-webfont.woff') format('woff'),
  url('../fonts/roboto_light/Roboto-Light-webfont.ttf') format('truetype'),
  url('../fonts/roboto_light/Roboto-Light-webfont.svg#RobotoLight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoLightItalic';
  src: url('../fonts/roboto_lightitalic/Roboto-LightItalic-webfont.eot');
  src: url('../fonts/roboto_lightitalic/Roboto-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto_lightitalic/Roboto-LightItalic-webfont.woff') format('woff'),
  url('../fonts/roboto_lightitalic/Roboto-LightItalic-webfont.ttf') format('truetype'),
  url('../fonts/roboto_lightitalic/Roboto-LightItalic-webfont.svg#RobotoLightItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoThin';
  src: url('../fonts/roboto_thin/Roboto-Thin-webfont.eot');
  src: url('../fonts/roboto_thin/Roboto-Thin-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/roboto_thin/Roboto-Thin-webfont.woff') format('woff'),
  url('../fonts/roboto_thin/Roboto-Thin-webfont.ttf') format('truetype'),
  url('../fonts/roboto_thin/Roboto-Thin-webfont.svg#RobotoThin') format('svg');
  font-weight: normal;
  font-style: normal;
}