.pane-block.pane-webform {
  h2.pane-title {
    //margin: 0 0 39px;
    text-align: left;
  }

  form.webform-client-form {

    label {
      font-family: $robotoLight;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 5px;
    }

    .webform-component--name,
    .webform-component--phone,
    .webform-component-email,
    .webform-component--subject {
      width: 100%;
      float: none;
      margin: 0 0 20px;
    }

    .form-actions {
      margin: 20px 0;
    }

    @include breakpoint($bp-mobile-l) {
      .webform-component--name,
      .webform-component--phone,
      .webform-component-email,
      .webform-component--subject {
        width: 50%;
        float: left;
        margin: 0 0 20px;
      }

      .webform-component--name,
      .webform-component-email {
        padding-right: 15px;
      }

      .webform-component--phone,
      .webform-component--subject {
        padding-left: 15px;
      }

      .form-actions {
        max-width: 175px;
        margin-left: auto;
      }
    }
  }

}