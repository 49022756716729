.node.node-room.view-mode-teaser {
  .room-content {
    background: center center no-repeat;
    background-size: cover;
    min-height: 220px;
    padding: 8px;
    .room-infobox-wrapper {
      width: 100%;
      .room-infobox {
        display: inline-block;
        background-color: rgba($brown, .8);
        padding: 10px;
        margin-bottom: 10px;
        .room-title {
          font-family: $robotoRegular;
          font-size: 17px;
          line-height: 24px;
          color: $white;
        }
        .room-price {
          font-family: $robotoLight;
          font-size: 17px;
          line-height: 24px;
          color: $white;
          margin-bottom: 15px;
        }
        .room-facilities {
          display: flex;
          flex-wrap: wrap;
          .facility {
            width: 35px;
            margin-right: 5px;
            margin-bottom: 5px;
            img {
              display: block;
            }
          }
        }
      }
    }
    .view-room {

      a {
        display: block;
        background-color: rgba($brown, .8);
        font-family: $robotoBold;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 2px;
        color: $black;
        text-transform: uppercase;
        text-align: center;
        @include set-transition();
        &:hover {
          background-color: rgba($brown, 1);
        }
      }
    }
  }
}