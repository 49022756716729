// This removes default safari styling so do not remove next lines
textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}
// End


input {
  width: 100%;
  border: 1px solid $brown;
  padding: 5px 10px;
  font-size: 16px;
  line-height: 16px;
  font-family: $robotoLight;
}

input[type='color'],
input[type='range'] {
  padding: 0;
}

input[type='radio'],
input[type='checkbox'] {
  margin-right: 5px;
}

input.form-submit {
  border: none;
  padding: 10px 15px;
  background-color: $brown;
  font-family: $robotoBold;
  font-size: 17px;
  line-height: 17px;
  text-transform: uppercase;
  color: $white;
  cursor: pointer;
  @include set-transition();
  &:hover {
    background-color: $brown-dark;
  }
}

textarea {
  width: 100% !important;
  min-height: 100px;
  border: 1px solid $brown;
  padding: 5px 10px;
  font-size: 16px;
  line-height: 16px;
  font-family: $robotoLight;
}

input, textarea {
  &:focus {
    outline: none;
  }
}

select {
  padding: 5px 10px;

  &[multiple='multiple'] {
    padding: 0;
    option {
      padding: 3px 10px;
    }
  }
}