.pane-km-frontpage-fb-posts {

}

#km-fb-page {
  text-align: center;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  iframe {
    width: 100% !important;
  }
  blockquote {
    padding: 0;
  }
}