// Breakpoints - sizes from google chrome device preview mode
// Style development - mobile first (start from small screens)
$bp-mobile-s: 320px;
$bp-mobile-m: 375px;
$bp-mobile-l: 425px;
$bp-tablet: 768px;
$bp-desktop: 1024px;
$bp-desktop-l: 1440px;
$bp-desktop-xl: 2560px; // 4k screen


// Pages layout settings
$container-width: 1140px; // Global limit
$content-width: 800px; // Used for nodes, terms
$container-padding: 20px;
$block-vertical-margin: 35px;


// Transition speed
$transition-speed: .2s;


// Colors
$white: #ffffff;
$grey: #afafaf;
$grey_dark: #696969;
$black: #000000;

// Primary colors
$brown: #a96f00;
$brown-light: #aa832d;
$brown-dark: #4e2c22;

$grey-light: #f2f2f2;

// Secondary colors



// Fonts
$robotoRegular: 'RobotoRegular', sans-serif;
$robotoBold: 'RobotoBold', sans-serif;
$robotoLight: 'RobotoLight', sans-serif;
$robotoLightItalic: 'RobotoLightItalic', sans-serif;
$robotoThin: 'RobotoThin', sans-serif;


// FontAwesome font files path
$fa-font-path: "../fonts/FontAwesome";