.node.node-room.view-mode-full {

  .room-name {
    width: 100%;
    font-family: $robotoLight;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    margin-right: 20px;
    margin-bottom: 40px;
    white-space: nowrap;

    @include breakpoint($bp-tablet) {
      text-align: left;
    }
  }

  .room-info-content {
    display: flex;
    flex-direction: column;
    @include breakpoint($bp-tablet) {
      flex-direction: row;
    }

    .room-main-image {
      max-width: 360px;
      align-self: center;
      margin-bottom: 45px;
      @include breakpoint($bp-tablet) {
        flex: 0 0 360px;
        margin-right: 30px;
        align-self: normal;
      }
      img {
        display: block;
      }
    }
    .room-info {
      @include breakpoint($bp-tablet) {
        flex: 1 1 auto;
      }

      .room-reserve {
        margin-bottom: 20px;

        a {
          display: block;
          font-family: $robotoBold;
          font-size: 17px;
          text-transform: uppercase;
          color: $brown;
          background-color: $white;
          border: 1px solid $brown;
          text-align: center;
          width: 240px;
          padding: 5px 15px;
          margin: 0 auto;
          @include breakpoint($bp-tablet) {
            margin: 0;
          }
          @include set-transition();
          &:hover {
            color: $white;
            background-color: $brown;
          }
        }
      }

      .room-infobox {
        margin-bottom: 10px;
        .room-facilities {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          @include breakpoint($bp-tablet) {
            justify-content: left;
          }
          .facility {
            width: 35px;
            margin-bottom: 20px;
            @include breakpoint($bp-tablet) {
              margin-bottom: 10px;
            }
            img {
              display: block;
            }
          }
        }
      }
      .room-price {
        font-family: $robotoBold;
        font-size: 17px;
        margin-bottom: 45px;
        text-align: center;
        @include breakpoint($bp-tablet) {
          text-align: left;
        }
      }
    }
  }

  .room-content {
    margin-bottom: 45px;
    .room-infotext {
      margin-bottom: 25px;
      text-align: center;
      @include breakpoint($bp-tablet) {
        text-align: left;
      }
    }
  }

  .room-gallery {
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: center;
    //margin: 0px -15px;
    //
    //.room-gallery-image {
    //  max-width: 250px;
    //  width: 100%;
    //  margin: 0 13px 26px;
    //  img {
    //    display: block;
    //  }
    //}

    .field-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0px -15px;

      .field-item {
        max-width: 250px;
        width: 100%;
        margin: 0 13px 26px;
        img {
          display: block;
        }
      }

    }

  }
}