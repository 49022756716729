.room-page-header-wrapper {
  height: 150px;
  background: center center no-repeat;
  background-size: cover;
  margin-bottom: 45px;

  .back-link {
    @include set-container-width($content-width, 0);
    position: relative;
    height: 100%;
    a {
      display: block;
      width: 70px;
      height: 35px;
      background: url(../images/room_back_button.png) center center no-repeat;
      background-size: contain;
      position: absolute;
      left: $container-padding;
      bottom: 35px;
    }
  }

}