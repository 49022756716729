.km-events--container{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 25px;
  @media only screen and (max-width: 786px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 320px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .km-events--event{
    position: relative;
    margin:0 2.5px;
    background-size: cover;
    background-position: center;
    height: 250px;
    max-height: 250px;

    a{
      color: #fff;
      width: 100%;
      text-align: center;
      font-size: 22px;
      @media only screen and (max-width: 1040px) {
        font-size: 16px;
      }
      @media only screen and (max-width: 786px) {
        font-size: 22px;
      }
      @media only screen and (max-width: 320px) {
        font-size: 22px;
      }

      &:before{
        content: ' ';
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }

      &:after{
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border: 10px solid #fff;
        box-sizing: border-box;
        background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(170,131,45,0.89)), to(rgba(170,131,45,0.89)));
        opacity: 0;
        transition: opacity 0.2s, border 0.2s;
      }

      &:hover:after{
        opacity: 1;
      }
    }

    .km-event--title, .km-event--date{
      position: absolute;
      bottom: 70px;
      display: block;
      width: 100%;
      text-align: center;
      z-index: 1;
    }

    .km-event--date{
      bottom: 40px;
    }
  }
}


.km-event--single{
  max-width: 840px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 35px auto;
}

