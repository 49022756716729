.pane-bean-frontpage-gallery {
  .bean-gallery {

    .block-title {
      @include set-container-width($container-width, 0);
    }

    .gallery-slides-wrapper {
      overflow: hidden;
      position: relative;
      background: $black;

      .gallery-slides {
        @include set-container-width($container-width, 0);
        padding: 10px 0;
        overflow: hidden;
        .gallery-slide {
          width: 430px;
          @include breakpoint($bp-tablet) {
            width: 590px;
          }
          @include clearfix();
          img {
            margin: 7px;
            float: left;
            display: block;
            &.small {
              max-width: 150px;
              @include breakpoint($bp-tablet) {
                max-width: 210px;
              }
            }
            &.large {
              max-width: 250px;
              @include breakpoint($bp-tablet) {
                max-width: 350px;
              }
            }
          }
        }
      }

      .gallery-slider-control {
        position: absolute;
        top: 0;
        height: 100%;
        width: 30px;
        cursor: pointer;
        background: url(../images/slider_arrow_white.png) no-repeat rgba($black, .8);
        background-size: 14px;
        @include set-transition();

        @include breakpoint($bp-tablet) {
          width: 50px;
          background-size: inherit;
        }

        &.slide-left {
          left: 0;
          background-position: 6px center;
          @include breakpoint($bp-tablet) {
            background-position: 12px center;
          }
        }
        &.slide-right {
          right: 0;
          background-position: 6px center;
          transform: rotate(180deg);
          @include breakpoint($bp-tablet) {
            background-position: 14px center;
          }
        }

        &:hover {
          background-color: rgba($black, .95);
        }

      }
    }
  }
}