* {
  box-sizing: border-box; // Set normal sizing
}

html,
body {
  height: 100%;
}

body {
  font-family: $robotoLight;
  font-size: 16px;
  line-height: 20px;
  color: $black;

  @include set-transition('padding-top');
}

h1 {
  font-family: $robotoThin;
  font-size: 38px;
  line-height: 44px;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
  margin: 55px auto;
  max-width: $content-width;
  @include breakpoint($bp-tablet) {
    font-size: 40px;
    line-height: 50px;
  }
}
h2 {
  font-family: $robotoLight;
  font-size: 30px;
  line-height: 36px;
  margin: 35px 0;
  font-weight: normal;
}
h3 {
  font-family: $robotoThin;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: normal;
  margin: 45px 0 35px;
}
h4 {
  font-family: $robotoThin;
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
  margin: 40px 0 35px;

  a:after {
    //@include link-double-arrow();
    margin-left: 5px;
  }
}

a {
  color: $brown;
  text-decoration: none;
  @include set-transition();
  &:after {
    color: $black;
    @include set-transition();
  }
  &:hover {
    color: $brown-dark;
    &:after {
      color: $brown-dark;
    }
  }

  // link as button
  &.btn {
    position: relative;
    display: inline-block;
    font-family: $robotoBold;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    min-width: 240px;
    padding: 10px 20px;

    &.btn-white {
      background-color: rgba($white, .8);
      color: $brown-dark;
      &:hover {
        color: $brown;
        opacity: .8;
      }
    }
  }

}

p {
  margin: 0 0 15px;
  a {
    font-weight: bold;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.field-name-body {
  p img {
    height: auto !important;
  }
  ul {
    margin: 15px 0;
    padding: 0;
    line-height: 30px;
    list-style: none;
    li {
      list-style: none;
      padding-left: 25px;
      background: url(../images/list_icon.png) top left no-repeat;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }
}

ul {
  margin: 35px 0;
  padding-left: 16px;
}

blockquote {
  position: relative;
  font-family: $robotoLightItalic;
  font-size: 16px;
  line-height: 27px;
  margin: 35px 0;
  padding: 20px 60px 20px 60px;
  border: 1px solid $brown;
  border-radius: 5px;
  p {
    margin: 0;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 20px;
    left: 10px;
    background: url(../images/quotes.png) center center no-repeat;
    background-size: contain;
    width: 35px;
    height: 26px;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 20px;
    right: 10px;
    background: url(../images/quotes.png) center center no-repeat;
    background-size: contain;
    width: 35px;
    height: 26px;
    transform: rotate(180deg);
  }
}

figure.caption-img {
  margin: 35px 0;
  img {
    margin-bottom: 10px;
  }
  figcaption {
    font-family: $robotoRegular;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
  }
}

div.link {
  margin: 40px 0;
  a {
    font-weight: bold;
    &:after {
      //@include link-single-chevron();
      margin-left: 5px;
      font-size: 12px;
    }
  }
}

div.preamble {
  font-family: $robotoRegular;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  margin: 0 auto 45px;
  max-width: $content-width;
}