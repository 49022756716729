.layout-container {
  height: inherit;
  display: flex;
  flex-direction: column;

  #header {
    position: fixed;
    //top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  #main {
    position: relative;
    flex: 2 0 auto;
    @include set-transition('margin-top');

    #header-placeholder {
      height: 160px;
      @include breakpoint($bp-desktop) {
        height: 90px;
      }
    }

    .tabs {
      @include set-container-width($container-width, 10px);
    }

    .panel-2col-stacked {
      .center-wrapper {
        @include set-container-width($content-width, 30px);
        @include clearfix();
        .panel-col-first,
        .panel-col-last {
          width: 100%;
          float: none;
        }
        .panel-col-first .inside {
          margin: 0 0 25px;
        }
        .panel-col-last .inside {
          margin: 0;
        }
        @include breakpoint($bp-tablet) {
          .panel-col-first,
          .panel-col-last {
            width: 50%;
            float: left;
          }
          .panel-col-first .inside {
            margin: 0 20px 0 0;
          }
          .panel-col-last .inside {
            margin: 0 0 0 20px;
          }
        }
      }
    }

  }

  div.fixed-width-wide {
    @include set-container-width();
  }
  div.fixed-width-narrow {
    @include set-container-width($content-width);
  }

}

body {
  //&.path-user,
  //&.page-403 {
  //  .block-system-main-block {
  //    @include set-container-width();
  //  }
  //}
  //
  //&.path-node {
  //  #main {
  //    nav.tabs {
  //      @include set-container-width($content-width, 0);
  //    }
  //  }
  //}

  &.page-user {
    #content {
      @include set-container-width($content-width);
    }
  }
}
