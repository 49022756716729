.entity-bean.bean-content-teaser-list {
  @include set-container-width($content-width);

  .field-name-field-teasers {
    .field-items {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include breakpoint($bp-tablet) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;
        align-items: normal;
      }
      .field-item {
        width: 100%;
        max-width: 360px;
        margin-bottom: 35px;

        @include breakpoint($bp-tablet) {
          width: 50%;
          max-width: none;
          &.even {
            padding-right: 30px;
          }
          &.odd {
            padding-left: 30px;
          }
        }
      }
    }
  }

}