#footer {

  #block-bean-footer-info {
    @include set-container-width($container-width, 60px);

    .bean-footer-info {
      .footer-logo {
        max-width: 74px;
        margin: 0 auto 30px;
      }
      .footer-text {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-family: $robotoLight;

        @include breakpoint($bp-desktop) {
          flex-wrap: nowrap;
        }

        div {
          white-space: nowrap;
          padding: 0 20px;
          width: 100%;
          margin-bottom: 30px;
          text-align: center;
          @include breakpoint($bp-desktop) {
            width: auto;
            margin-bottom: 0;
          }
          a {
            color: $brown;
            @include set-transition();
            &:hover {
              color: $brown-dark;
            }
          }

          @include breakpoint($bp-desktop) {
            &:not(:last-of-type) {
              border-right: 1px solid $black;
            }
          }
        }
      }
    }

  }
}