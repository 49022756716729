// Example for advanced mixins, this config have autoprefixer so no need browser specific styling
//@mixin set-transition($property:'all', $duration:1s, $timingfunc:'', $delay:'') {
//  transition: #{$property} #{$duration} #{$timingfunc} #{$delay};
//}

@mixin set-transition($transition:'all', $speed:$transition-speed, $effect:'ease-in-out') {
  transition: #{$transition} #{$speed} #{$effect};
}

@mixin set-container-width($width:$container-width, $verticalMargin:$block-vertical-margin, $side-padding:$container-padding) {
  max-width: $width + ( 2 * $side-padding );
  padding-left: $side-padding;
  padding-right: $side-padding;
  margin: $verticalMargin auto;
}

@mixin disable-highlighting() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

@mixin clearfix() {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}



//@mixin link-single-arrow {
//  @include fa-icon();
//  content: $fa-var-angle-right;
//  display: inline;
//}
//
//@mixin link-double-arrow {
//  @include fa-icon();
//  content: $fa-var-angle-double-right;
//  display: inline;
//}
//
//@mixin link-single-chevron {
//  @include fa-icon();
//  content: $fa-var-chevron-right;
//  display: inline;
//}