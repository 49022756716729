.bean-info-content {
  width: 100%;
  padding: 20px 20px;

  @include breakpoint($bp-mobile-l) {
    width: 390px;
  }

  .info-content-inner {
    background-color: rgba($brown-light, .89);

    .info-content-title {
      font-size: 26px;
      line-height: 30px;
      color: $white;
      padding: 30px 25px;
      text-align: center;
    }

    .info-content-content {
      background: center center no-repeat;
      background-size: cover;
      min-height: 210px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 50px;
      justify-content: flex-end;

      .info-content-link {
        margin-top: 30px;
        width: 100%;
        a {
          width: 100%;
        }
      }

    }

  }

}