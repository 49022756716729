.pane-blog-list {
  @include set-container-width($content-width);

  .pane-content {
    .view-blog-list {
      .view-content {
        .views-row {
          display: flex;
          flex-direction: column;
          margin-bottom: 50px;

          @include breakpoint(600px) {
            flex-direction: row;
            flex-wrap: nowrap;
            margin-bottom: 30px;
          }


          .image {
            margin-bottom: 10px;
            @include breakpoint(600px) {
              flex: 0 0 240px;
              margin-right: 20px;
            }
          }

          .infotext {
            h2 {
              font-size: 20px;
              line-height: 24px;
              margin-top: 0;
              margin-bottom: 20px;

              @include breakpoint(600px) {
                font-size: 30px;
                line-height: 36px;
              }
            }
          }

        }
      }
    }
  }

}