.entity-bean.bean-simple-content-block {
  @include set-container-width($content-width);
  .field-name-field-block-title {
    h1 {
      font-family: $robotoLight;
      font-size: 30px;
      line-height: 36px;
      margin: 0 0 35px;
      font-weight: normal;
      text-align: left;
      text-transform: none;
    }
  }
  .field-name-field-block-content {
    line-height: 22px;
    p {
      margin-bottom: 25px;
    }
  }
}