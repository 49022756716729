.pane-bean-frontpage-info-block {
  .pane-content {
    .bean-frontpage-info {
      .content {
        position: relative;
        background: center center no-repeat;
        background-size: cover;
        padding: 70px 0;

        @include breakpoint($bp-desktop) {
          min-height: 850px;
        }

        .slider {
          z-index: 1;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          .slides {
            width: inherit;
            height: inherit;
            .slide {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: center center no-repeat;
              background-size: cover;

              .slide-title-wrapper {
                @include set-container-width($container-width, 0);
                padding-top: 150px;
                @include breakpoint(650px) {
                  padding-top: 70px;
                }
                .slide-title {
                  @include breakpoint(650px) {
                    margin-right: 300px;
                  }
                  h2 {
                    font-family: $robotoThin;
                    font-weight: 100;
                    font-size: 44px;
                    line-height: 50px;
                    text-align: center;
                    color: $white;
                    margin: 0;
                    p {
                      margin: 0;
                    }

                    @include breakpoint(650px) {
                      font-size: 61px;
                      line-height: 63px;
                      text-align: left;
                    }
                  }
                }
              }

            }
          }

        }

        .frontpage-info-inner {
          @include set-container-width($container-width, 0);
          z-index: 5;
          * {
            z-index: 5;
          }

          .frontpage-info-text {
            .frontpage-phone {
              text-align: center;
              margin-bottom: 300px;
              a {
                display: block;
                color: $black;
                @include clearfix();

                .phone-inner {
                  position: relative;
                  margin-left: auto;
                  margin-right: auto;
                  width: 220px;
                  height: 50px;
                  background: url(../images/phone_icon.svg) center center no-repeat;
                  background-size: contain;
                  @include set-transition();
                  @include breakpoint(650px) {
                    margin-right: 0;
                  }
                  &:hover {
                    opacity: .8;
                  }
                  .phone {
                    position: absolute;
                    left: 0;
                    top: 18px;
                    width: 100%;
                    text-align: center;
                    font-family: $robotoBold;
                    font-size: 14px;
                    line-height: 14px;
                  }
                }
              }
            }
          }

          .frontpage-info-blocks {
            display: none;
            @include breakpoint($bp-desktop) {
              display: block;
            }

            .field-items {
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;
              justify-content: center;
              margin: 0 -20px;

              @include breakpoint($bp-mobile-l) {
                flex-direction: row;
              }
            }
          }
        }

      }
    }
  }
}