#header {
  background-color: $white;

  .header-inner {
    @include set-container-width($container-width, 0);
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    min-height: 90px;

    @include breakpoint($bp-desktop) {
      flex-direction: row;
    }

    .logo-wrapper {
      width: 102px;
      margin-top: 7px;
      margin-bottom: 20px;
      @include breakpoint($bp-desktop) {
        margin-top: 0;
        margin-bottom: 0;
      }

    }

    .region-header {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @include breakpoint($bp-desktop) {
        justify-content: right;
        width: auto;
      }

      .page-main-menu-mobile-block {
        position: fixed;
        top: 160px;
        bottom: 0;
        background: $white;
        left: -320px;
        width: 320px;
        border-top: 1px solid $brown;
        @include set-transition(all .5s);
        @include breakpoint($bp-desktop) {
          display: none !important;
        }

        ul, li {
          list-style: none;
          margin: 0;
          padding: 0;
        }

        ul.menu {
          li {
            position: relative;
            span.nolink, a {
              color: $black;
              display: block;
              border-bottom: 1px solid $brown;
              padding: 10px 40px 10px 20px;
              cursor: pointer;
              @include set-transition();

              &.active,
              &:hover {
                color: $brown;
              }
            }

            span.arrow {
              display: block;
              width: 40px;
              height: 40px;
              position: absolute;
              top: 0;
              right: 0;
              cursor: pointer;
              @include set-transition();

              &.open,
              &:hover {
                color: $brown;
              }

              &:after {
                @include fa-icon();
                display: block;
                content: $fa-var-chevron-right;
                width: 100%;
                text-align: center;
                margin-top: 12px;
                @include set-transition();
              }

              &.open:after {
                transform: rotate(90deg);
              }
            }

            ul.menu {
              display: none;
              span.nolink, a {
                padding-left: 40px;
              }
            }
          }
        }

      }

      .page-main-menu-block {
        display: none;
        @include breakpoint($bp-desktop) {
          display: block;
        }

        .menu-block-wrapper {
          ul, li {
            list-style: none;
            margin: 0;
            padding: 0;
          }

          > ul.menu {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            > li {
              position: relative;
              >span.nolink,
              >a {
                color: $black;
                display: block;
                padding: 10px 10px;
                cursor: pointer;
                font-family: $robotoLight;
                font-size: 19px;
                white-space: nowrap;
                @include set-transition();

                @include breakpoint(1100px) {
                  font-size: 20px;
                }

                &.active,
                &:hover {
                  color: $brown;
                }
              }

              // 2nd lvl - dropdown
              ul.menu {
                display: none;
                position: absolute;
                top: 100%;
                background-color: $white;
                min-width: 200px;
                li {
                  span.nolink,
                  a {
                    color: $black;
                    display: block;
                    padding: 10px 10px;
                    white-space: nowrap;
                    font-family: $robotoLight;
                    font-size: 16px;
                    cursor: pointer;
                    @include set-transition();
                    &:hover {
                      color: $brown;
                    }
                  }
                }
              }

            }
          }
        }
      }

      #block-locale-language {
        margin-left: 25px;
        ul, li {
          list-style: none;
          margin: 0;
          padding: 0;
        }

        ul.language-switcher-locale-url {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          li {
            a {
              color: $black;
              display: block;
              padding: 10px 5px;
              cursor: pointer;
              font-family: $robotoLight;
              font-size: 18px;
              @include set-transition();

              &:hover {
                color: $brown;
              }
            }
            &.active {
              display: none;
            }
          }
        }
      }
    }
  }
}

body.admin-menu {
  #header {
    .header-inner {
      .region-header {
        .page-main-menu-mobile-block {
          top: 189px;
        }
      }
    }
  }
}