.pane-bean-we-recommend {
  .pane-content {
    .bean-we-recommend {

      .items-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .item {
          width: 170px;
          padding: 0 20px;
          text-align: center;
          margin-bottom: 30px;
        }
      }

    }
  }
}
