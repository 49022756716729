.content-header-wrapper {
  height: 300px;
  background: center center no-repeat;
  background-size: cover;

  .content-header-title {
    @include set-container-width($content-width, 0);
    height: 100%;
    position: relative;

    h1 {
      position: absolute;
      left: $container-padding;
      bottom: 35px;
      margin: 0;
      color: $white;
      text-transform: none;
      font-size: 42px;
      line-height: 46px;
      text-align: left;

      @include breakpoint($bp-mobile-l) {
        font-size: 61px;
        line-height: 63px;
      }
    }

  }

}