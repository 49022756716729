#block-km-mobile-menu-burger {
  @include breakpoint($bp-desktop) {
    display: none !important;
  }

  #menu-burger-wrapper {
    position: relative;
    display: block;
    height: 36px;
    width: 36px;
    margin-top: 16px;
    cursor: pointer;
    overflow: hidden;

    &:focus {
      outline: transparent;
    }

    * {
      @include set-transition();
    }

    span {
      display: block;
      background: $black;
      border-radius: 2px;
    }

    #hamburger {
      position: absolute;
      height: 100%;
      width: 100%;
      span {
        width: 100%;
        height: 4px;
        position: relative;
        top: 0;
        left: 0;

        &:nth-child(1){
          transition-delay: .5s;
          margin: 5px 0 7px;
        }
        &:nth-child(2){
          transition-delay: .625s;
          margin: 7px 0;
        }
        &:nth-child(3){
          transition-delay: .75s;
          margin: 7px 0 5px;
        }
      }
    }
    #cross {
      position: absolute;
      height: 100%;
      width: 100%;
      transform: rotate(45deg);
      span {
        &:nth-child(1) {
          height: 0%;
          width: 4px;
          position: absolute;
          top: 0;
          left: 16px;
          transition-delay: 0s;
        }
        &:nth-child(2) {
          width: 0%;
          height: 4px;
          position: absolute;
          left: 0;
          top: 16px;
          transition-delay: .25s;
        }
      }
    }

    &.open {
      #hamburger {
        span {
          width: 0%;
          &:nth-child(1) {
            transition-delay: 0s;
          }
          &:nth-child(2) {
            transition-delay: .125s;
          }
          &:nth-child(3) {
            transition-delay: .25s;
          }
        }
      }
      #cross {
        span {
          &:nth-child(1) {
            height: 100%;
            transition-delay: .625s;
          }
        }
        span {
          &:nth-child(2) {
            width: 100%;
            transition-delay: .375s;
          }
        }
      }
    }
  }
}

.pane-block  h2.pane-title,
.entity-bean .block-title {
  text-align: center;
}

.entity-bean .block-page-title {
  text-align: center;
  @include breakpoint($bp-tablet) {
    text-align: left;
  }
}