.bean-restaurant-menu {
  @include set-container-width($content-width);

  .restaurant-menu-wrapper {
    font-size: 18px;
    .restaurant-menu-header {
      display: none;
      text-align: right;
      font-weight: bold;
      margin-bottom: 20px;
      @include breakpoint($bp-mobile-l) {
        display: block;
      }
    }
    .restaurant-menu-item {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid rgba($brown, .2);

      .restaurant-menu-name {
        margin-bottom: 10px;
      }
      .restaurant-menu-price {
        font-weight: bold;
        white-space: nowrap;
        color: $brown;
        .currency {
          color: $black;
          margin-right: 10px;
        }
      }

      @include breakpoint($bp-mobile-l) {
        flex-direction: row;
        justify-content: space-between;

        .restaurant-menu-name {
          padding-right: 20px;
          margin-bottom: 0;
        }
        .restaurant-menu-price {
          padding-left: 20px;
          .currency {
            display: none;
          }
        }
      }
    }
  }
}