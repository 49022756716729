.entity-bean.bean-team {
  @include set-container-width($content-width);

  .team-members-wrapper {
    .team-member {
      margin-bottom: 60px;
      @include clearfix();

      .member-image{
        margin-bottom: 35px;
        img {
          margin: 0 auto;
          border-radius: 100%;
          overflow: hidden;
          display: block;
        }

        @include breakpoint($bp-tablet) {
          float: left;
          margin-right: 40px;
          margin-bottom: 0;
          img {
            margin: 0;
          }
        }

      }

      .member-description {

        .member-name {
          font-family: $robotoRegular;
          font-size: 16px;
          line-height: 20px;
          color: $brown;
          text-align: center;
        }
        .member-position {
          font-family: $robotoLightItalic;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 15px;
          text-align: center;
        }
        .member-info {
          font-family: $robotoLight;
          font-size: 16px;
          line-height: 20px;
        }

        @include breakpoint($bp-tablet) {
          margin-left: 190px;
          .member-name,
          .member-position {
            text-align: left;
          }
        }

      }

    }
  }

}